import './App.css';
import logo1 from "./1.jpg";
import video1 from './1. FOREX Trading Basics.mp4';
import payout from './payout.jpeg';
import phase1 from './phase1.jpeg';
import phase2 from './phase2.jpeg';
import certificate1 from './certificate1.jpeg';
import certificate2 from './certificate2.jpeg';
// import phase1_stats1 from './phase1_stats1.jpeg';
// import phase1_stats2 from './phase1_stats2.jpeg';
// import phase2_stats1 from './phase2_stats1.jpeg';
import live from './live mt4 withdraw.jpeg';
import funded from './funded mt4 withdraw.jpeg';
import withdraw from './3771 witdraw proof.jpeg';
import binance from './binance witdraw proof.jpeg';
import shane1 from './shane proof1.jpeg';
import shane2 from './shane proof2.jpeg';
import shane3 from './shane proof3.jpeg';
import shane4 from './shane proof4.jpeg';
import shane5 from './shane proof5.jpeg';
import sylvester1 from './sylvester proof1.jpeg';
import sylvester2 from './sylvester proof2.jpeg';
import sylvester3 from './sylvester proof3.jpeg';
import sylvester4 from './sylvester proof4.jpeg';
import sylvester5 from './sylvester proof5.jpeg';
import florence1 from './florence proof1.jpeg';
import florence2 from './florence proof2.jpeg';
import florence3 from './florence proof3.jpeg';
import florence4 from './florence proof4.jpeg';
import florence5 from './florence proof5.jpeg';
import bhanu1 from './bhanu proof1.jpeg';
import bhanu2 from './bhanu proof2.jpeg';
import bhanu3 from './bhanu proof3.jpeg';
import bhanu4 from './bhanu proof4.jpeg';
import bhanu5 from './bhanu proof5.jpeg';
import banner from './banner.jpeg'


function App() {
  const handleWhatsAppClick = () => {
    const phoneNumber = "9738173393";
    const message = "Hello, I would like to schedule a call!";
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={logo1} alt="logo" />
        <button className="contact-button" onClick={handleWhatsAppClick}>
          Contact us
        </button>
      </header>
      <div className="App-content">
        <h1>How to make $1000 Profit Per Day with Forex Trading? </h1>
        <div className="video-container">
          <video controls>
            <source src={video1} type="video/mp4" />
          </video>
        </div>
      </div>
      
      <div className="course-content">
          <h2 className="container-heading">What You Will Master : The Complete Guide to Trading Excellence</h2>
          <ul className="course-points">
            <li>Market Structure</li>
            <li>Identifying Market Trends</li>
            <li>When to Place BUY Trades</li>
            <li>When to Place SELL Trades</li>
            <li>Take Profits and Stop Losses</li>
            <li>Risk Management</li>
            <li>Daily Trading Signals</li>
            <li>How to Get $100K to $500K from Funding Firms</li>
            <li>How to Pass Funding Firms' Trading Tests in a Few Days</li>
          </ul>
        </div>

      <div className="proofs-container">
        <h2 className="container-heading">My Trading Proofs</h2>
          <div className="images-row">
            <img src={phase1} alt="Proof 1" className="myproof-image" />

            <img src={phase2} alt="Proof 2" className="myproof-image" />
            <img src={payout} alt="Proof 2" className="myproof-image" />
            <img src={certificate1} alt="Proof 2" className="myproof-image" />
            <img src={certificate2} alt="Proof 2" className="myproof-image" />
            <img src={live} alt="Proof 2" className="myproof-image" />
            <img src={funded} alt="Proof 2" className="myproof-image" />
            <img src={withdraw} alt="Proof 2" className="myproof-image" />
            <img src={binance} alt="Proof 2" className="myproof-image" />
            
          </div>
      </div>

      <div className="proofs-container">
        <h2 className="container-heading">Few Students Trading Results</h2>
        <h3 style={{padding:'10px',backgroundColor:'#0047ab',display:'inline-block',borderRadius:'8px',color:'white'}} >Shane</h3>
          <div className="images-row">
            <img src={shane1} alt="Proof 1" className="proof-image" />
            
            <img src={shane2} alt="Proof 2" className="proof-image" />
            <img src={shane3} alt="Proof 2" className="proof-image" />
            
            <img src={shane4} alt="Proof 2" className="proof-image" />
            <img src={shane5} alt="Proof 2" className="proof-image" />
          </div>

          <h3 style={{padding:'10px',backgroundColor:'#0047ab',display:'inline-block',borderRadius:'8px',color:'white'}}>Sylvester</h3>
          <div className="images-row">
            <img src={sylvester1} alt="Proof 1" className="proof-image" />
            
            <img src={sylvester2} alt="Proof 2" className="proof-image" />
            <img src={sylvester3} alt="Proof 2" className="proof-image" />
            
            <img src={sylvester4} alt="Proof 2" className="proof-image" />
            <img src={sylvester5} alt="Proof 2" className="proof-image" />
  
          </div>

          <h3 style={{padding:'10px',backgroundColor:'#0047ab',display:'inline-block',borderRadius:'8px',color:'white'}}>Florence</h3>
          <div className="images-row">
            <img src={florence1} alt="Proof 1" className="proof-image" />
            
            <img src={florence2} alt="Proof 2" className="proof-image" />
            <img src={florence3} alt="Proof 2" className="proof-image" />
            
            <img src={florence4} alt="Proof 2" className="proof-image" />
            <img src={florence5} alt="Proof 2" className="proof-image" />
  
          </div>

          <h3 style={{padding:'10px',backgroundColor:'#0047ab',display:'inline-block',borderRadius:'8px',color:'white'}}>Bhanu Prakash</h3>
          <div className="images-row">
            <img src={bhanu1} alt="Proof 1" className="proof-image" />
            
            <img src={bhanu2} alt="Proof 2" className="proof-image" />
            <img src={bhanu3} alt="Proof 2" className="proof-image" />
            
            <img src={bhanu4} alt="Proof 2" className="proof-image" />
            <img src={bhanu5} alt="Proof 2" className="proof-image" />
  
          </div>
      </div>
      <div style={{display:'flex',justifyContent:"center",marginTop:'10px',}}>
        <p style={{marginRight:'10px',fontWeight:'bold'}}>Trade Smarter, Not Harder: Learn How with Our Course!</p>
        <button className="contact-button1" onClick={handleWhatsAppClick}>
          Contact us
        </button>
        {/* <button style={{border:'none',backgroundColor:'blue',color:'white',padding:'2px 5px',fontSize:'16px',borderRadius:'8px'}} onClick={handleWhatsAppClick}>
          Contact us
        </button> */}
      </div>

    </div>
  );
}

export default App;
